import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { useLocation } from '~/hooks/useLocation';
import type { GetContestsPayload } from '~/services/contests/contests.service.api';
import { getContests } from '~/services/contests/contests.service.api';

export const useContests = (
  payload: GetContestsPayload,
  queryOpts?: { enabled?: boolean; keepPreviousData?: boolean }
) => {
  const { user } = useContext(UserContext);
  const { locationState } = useLocation();

  const data = useQuery({
    // Using locationState as query key, to update available contests when detected state changes
    queryKey: ['contests', payload, user?.id, locationState, payload.filter?.contestIds?.join(',')],
    queryFn: () => getContests(payload),
    keepPreviousData: queryOpts.keepPreviousData ?? true,
    ...queryOpts,
  });

  return data;
};
