import { Box, createStyles, Flex, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { ContestStatus, ContestType } from '@betterpool/api-types/contests-service';

import { ContestStatusFilter } from '~/domains/contest/domains/services/enums/contest-status-filter.enum';
import { Routes } from '~/domains/common/constants/routes';
import ContestCard from '~/domains/contest/domains/common/components/ContestCard/ContestCard';
import useEntries from '~/domains/contest/domains/entries/hooks/useEntries';
import useGetActionBannerInfo from '~/domains/contest/domains/entries/hooks/useGetActionBannerInfo';
import IconClipboard from '~/domains/design-system/icons/IconClipboard';
import { URLQueryKeys } from '~/domains/common/types/URLQueryKeys';
import { reportEvent } from '~/domains/analytics';

import HomeSkeletonLayout from './HomeSkeleton';
import HomeCarousel from './HomeCarousel';
import { HomeSectionTitle, HomeViewAllLink } from './sectionComponents';

const useStyles = createStyles((theme) => ({
  icon: {
    display: 'flex',
    alignSelf: 'center',
    position: 'relative',
  },
  iconIndicator: {
    position: 'absolute',
    top: -1,
    right: -1,
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: theme.colors.red[6],
  },
}));

function MyEntries() {
  const {
    itemsTotal: entriesActionRequiredTotal,
    isLoading,
    isError,
    contests,
  } = useEntries({
    status: ContestStatusFilter.ACTION_REQUIRED,
    limit: 9,
  });
  const theme = useMantineTheme();
  const { t } = useTranslation('home');
  const { getActionBannerInfo } = useGetActionBannerInfo();
  const { classes } = useStyles();

  if (isLoading) {
    return <HomeSkeletonLayout isLoading />;
  }

  if (isError || !contests.length) {
    return null;
  }

  return (
    <Flex direction="column">
      <Flex justify="space-between" w="100%" align="baseline">
        <Flex align="baseline" gap={theme.other.spacing._8}>
          <div className={classes.icon}>
            <IconClipboard strokeWidth={2.5} />
            <div className={classes.iconIndicator} />
          </div>
          <HomeSectionTitle>
            {t('myEntriesNew.title__hasPlural', { count: entriesActionRequiredTotal })}
          </HomeSectionTitle>
        </Flex>
        <HomeViewAllLink
          href={Routes.entries({
            [URLQueryKeys.CONTEST_STATUS]: ContestStatusFilter.ACTION_REQUIRED,
          })}
          onClick={() =>
            reportEvent('Lobby > View More - My Entries', {
              contests: contests.length,
              actionsRequired: entriesActionRequiredTotal,
            })
          }
        />
      </Flex>
      <Box p={0} component="ul" mt={0} w="100%">
        <HomeCarousel>
          {contests?.map((contest) => (
            <HomeCarousel.Slide key={contest.id}>
              <ContestCard
                key={contest.id}
                isSurvivor={contest.contest_type === ContestType.TEAM_SURVIVOR}
                leaderboard={
                  contest.status !== ContestStatus.SCHEDULED ? contest?.leaderboard : undefined
                }
                contest={contest}
                survivor={contest?.survivor}
                isMyContestsView
                isContestThumbnailShown
                actionBanner={getActionBannerInfo(contest)}
              />
            </HomeCarousel.Slide>
          ))}
        </HomeCarousel>
      </Box>
    </Flex>
  );
}

export default MyEntries;
