import { Box, Flex, Stack } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { reportEvent } from '~/domains/analytics';
import { PlayerCell } from '~/domains/contest/domains/quickPick/components/Board/PlayerCell/PlayerCell';
import { useBoardContext } from '~/domains/contest/domains/quickPick/context/BoardContext/BoardContext';
import ConnectedPicksPanel from '~/domains/contest/domains/quickPick/components/Board/PicksPanel/ConnectedPicksPanel';
import useBoard from '~/domains/contest/domains/quickPick/components/Board/useBoard';
import ConnectedPlayerCard from '~/domains/contest/domains/quickPick/components/PlayerCard/ConnectedPlayerCard';
import ConnectedPicksDrawer from '~/domains/contest/domains/quickPick/components/Board/PicksDrawer/ConnectedPicksDrawer';
import { Routes } from '~/domains/common/constants/routes';

import HomeCarousel from './HomeCarousel';
import HomeSkeletonLayout from './HomeSkeleton';
import { HomeSectionTitle, HomeViewAllLink } from './sectionComponents';

function HomeQuickpicks({ order }: { order?: number }) {
  const { t } = useTranslation('common');
  const { filters } = useBoardContext();
  const { items, propsQuery } = useBoard({ isPromo: true });

  if (propsQuery.isLoading) {
    return <HomeSkeletonLayout isLoading />;
  }

  // TODO post-MVP: Proper error handling
  if (propsQuery.error || (!propsQuery.isLoading && (!items || items.length < 1))) return;

  return (
    <Stack spacing={0} style={{ order }}>
      <Flex justify="space-between" w="100%" align="baseline">
        <HomeSectionTitle>{t('quickPicksPromo.title')}</HomeSectionTitle>
        <HomeViewAllLink
          href={Routes.quickPicksBoard()}
          onClick={() => reportEvent('Lobby > View More - Props')}
        />
      </Flex>
      <Box p={0} component="ul" mt={0} w="100%">
        <HomeCarousel slideSize="calc(100% / 8)">
          {items?.map((prop) => (
            <HomeCarousel.Slide key={prop.id}>
              <PlayerCell {...prop} />
            </HomeCarousel.Slide>
          ))}
        </HomeCarousel>
      </Box>
      <ConnectedPicksPanel isPromo />
      <ConnectedPlayerCard
        playerId={filters.value.playerCardId}
        opened={!!filters.value.playerCardId}
        onClose={() => filters.set('playerCardId', null)}
      />
      <ConnectedPicksDrawer isPromo />
    </Stack>
  );
}

export default HomeQuickpicks;
